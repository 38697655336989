const links = document.querySelectorAll('a[href^="#"]');
links.forEach(item =>
  item.addEventListener('click', function (e) {
    e.preventDefault();
    const id = item.getAttribute('href').slice(1);

    document.getElementById(id).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  })
);

// mobile menu

const mobileMenu = document.querySelector('.mobmenu');
const openMenuBtn = document.querySelector('.open-mobmenu-btn');
const closeMenuBtn = document.querySelector('.close-mobmenu-btn');
const mobMenuNavButtons = document.querySelectorAll('.mobmenu-nav__button');

const toggleMenu = () => {
  mobileMenu.classList.toggle('is-open');
  const isMenuOpen = mobileMenu.classList.contains('is-open');

  isMenuOpen
    ? document.body.classList.add('is-open-for-body')
    : document.body.classList.remove('is-open-for-body');
};

openMenuBtn.addEventListener('click', toggleMenu);
closeMenuBtn.addEventListener('click', toggleMenu);
mobMenuNavButtons.forEach(btn => btn.addEventListener('click', toggleMenu));

window.matchMedia('(min-width: 601px)').addEventListener('change', e => {
  if (!e.matches) return;
  mobileMenu.classList.remove('is-open');
  document.body.classList.remove('is-open-for-body');
});

// smooth scroll 

document.getElementById('company-button-mob')?.addEventListener('click', () => {
  document.getElementById('company').scrollIntoView({ behavior: 'smooth' });
});

document.getElementById('product-button-mob')?.addEventListener('click', () => {
  document.getElementById('product').scrollIntoView({ behavior: 'smooth' });
});

document.getElementById('factory-button-mob')?.addEventListener('click', () => {
  document.getElementById('factory').scrollIntoView({ behavior: 'smooth' });
});

document
  .getElementById('contacts-button-mob')
  ?.addEventListener('click', () => {
    document.getElementById('contacts').scrollIntoView({ behavior: 'smooth' });
  });